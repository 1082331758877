<template>
  <div class="editPage">
    <Form @submit="onSubmit" input-align="right" :show-error-message="false">
      <div class="box" :class="form.id ? (ifSave=='save'?'':'tallerBox') :'tallBox'">
        <Field
          v-model="form.mobile"
          name="手机号"
          label="手机号"
          placeholder="请输入手机号"
          :maxlength="11"
          :rules="[{ required: true, message: '请输入正确格式手机号' }]"
          v-if="judgeParam('A')"
        />
        <Field
          v-model="form.name"
          name="姓名"
          label="姓名"
          :maxlength="10"
          placeholder="请输入参加人员姓名"
          :rules="[{ required: true, message: '请输入参加人员姓名' }]"
          v-if="judgeParam('B')"
        />

        <Field
          readonly
          clickable
          name="picker"
          :value="form.gender"
          label="性别"
          placeholder="请选择性别"
          right-icon="arrow"
          @click="handleShowPicker('gender')"
          v-if="judgeParam('C')"
          :rules="[{ required: true, message: '请选择性别' }]"
        />

        <Field
          v-model="form.nation"
          type="nation"
          name="民族"
          label="民族"
          :maxlength="10"
          placeholder="请输入民族"
          :rules="[{ required: true, message: '请输入民族' }]"
          v-if="judgeParam('D')"
        />
        <Field
          readonly
          clickable
          name="picker"
          :value="form.politicalOutlook"
          label="政治面貌"
          placeholder="请选择政治面貌"
          right-icon="arrow"
          @click="handleShowPicker('politicalOutlook')"
          v-if="judgeParam('E')"
          :rules="[{ required: true, message: '请选择政治面貌' }]"
        />
        <Field
          readonly
          clickable
          name="picker"
          :value="form.brithday"
          label="出生年月"
          placeholder="请选择出生年月"
          right-icon="arrow"
          @click="handleShowPicker('born')"
          v-if="judgeParam('F')"
          :rules="[{ required: true, message: '请选择出生年月' }]"
        />
        <Field
          v-model="form.papers"
          name="身份证号"
          label="身份证号"
          placeholder="请输入身份证号"
          :rules="[{ required: true, message: '请输入身份证号' }]"
          v-if="judgeParam('G')"
        />
        <Field
          v-model="form.company"
          name="单位名称"
          label="单位名称"
          :maxlength="50"
          placeholder="请输入单位名称"
          :rules="[{ required: true, message: '请输入单位名称' }]"
          v-if="judgeParam('H')"
        />
        <Field
          v-model="form.post"
          name="职务"
          label="职务"
          :maxlength="10"
          placeholder="请输入职务"
          :rules="[{ required: true, message: '请输入职务' }]"
          v-if="judgeParam('I')"
        />
        <Field
          readonly
          clickable
          name="area"
          :value="form.companyAddress"
          label="单位地址"
          placeholder="点击选择省市区"
          right-icon="arrow"
          @click="handleShowArea('companyAddress')"
          v-if="judgeParam('J')"
          :rules="[{ required: true, message: '请选择省市区' }]"
        />
        <Field
          v-model="form.companyDetailAddress"
          name="单位详细地址"
          label="详细地址"
          :maxlength="50"
          placeholder="请输入门牌号"
          v-if="judgeParam('J')"
        />
        <Field
          readonly
          clickable
          name="area"
          :value="form.dataAddress"
          label="资料邮寄地址"
          placeholder="点击选择省市区"
          right-icon="arrow"
          @click="handleShowArea('dataAddress')"
          v-if="judgeParam('K')"
          :rules="[{ required: true, message: '请选择邮寄地址' }]"
        />
        <Field
          v-model="form.dataDetailAddress"
          name="邮寄详细地址"
          label="详细地址"
          :maxlength="50"
          placeholder="请输入门牌号"
          v-if="judgeParam('K')"
        />
        <Field
          v-model="form.email"
          name="邮箱"
          label="邮箱"
          placeholder="请输入邮箱"
          :rules="[{ required: true, message: '请输入邮箱' }]"
          v-if="judgeParam('M')"
        />
        <Field
          v-model="form.remark"
          rows="3"
          autosize
          label="备注"
          type="textarea"
          :maxlength="200"
          placeholder="请输入备注说明"
          show-word-limit
          v-if="judgeParam('L')"
          :rules="[{required: true, message: '请输入备注说明' }]"
        />
      </div>
      <div class="line" v-if="ifSave=='save'"></div>
      <div class="btns" v-if="ifSave=='save'&&(info.refundStatus!=1&&info.refundStatus!=2)">
        <Button block native-type="submit" color="#2b72ff"
          >保存参会人信息</Button
        >
        <Button
          class="mar-t-15"
          native-type="button"
          block
          type="info"
          color="#F62F2F"
          v-if="form.id &&ifDelete=='yes'"
          @click="delAttender"
          >删除参会人</Button
        >
      </div>
    </Form>

    <Popup v-model="showPicker" position="bottom">
      <!-- // " -->
      <datetime-picker
        v-if="pickerType == 'born'"
        type="date"
        v-model="currentDate"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirmPicker"
        @cancel="cancelPicker"
      />
      <Picker
        v-else
        show-toolbar
        :columns="pickerList"
        @confirm="onConfirmPicker"
        @cancel="cancelPicker"
      />
    </Popup>
    <Popup v-model="showArea" position="bottom">
      <Area
        :area-list="areaList"
        @confirm="onConfirmArea"
        @cancel="showArea = false"
      />
    </Popup>
  </div>
</template>

<script>
import {
  Form,
  Field,
  Popup,
  Picker,
  Button,
  Area,
  DatetimePicker,
  Toast,
  Dialog,
  Notify,
} from "vant";
import { areaList } from "@vant/area-data";
import moment from "moment";
import { delAttender, generateMeetOrder } from "@/api/meet.js";
export default {
  name: "editAttendee",
  components: {
    Form,
    Field,
    Popup,
    Picker,
    Area,
    Button,
    DatetimePicker,
    [Dialog.Component.name]: Dialog.Component,
    [Notify.Component.name]: Notify.Component,
  },
  data() {
    return {
      meetId: "",
      meetStatus:'',
      applyField: [], //包含的字段代号
      pattern: /^(13|14|15|17|18|19|16)[0-9]{9}$/,
      idPattern: /^\d{17}[\d|x]|\d{15}$/,
      emailPattern: /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/,
      gender: "",
      genderList: ["女", "男"],
      politicList: ["群众", "党员", "其他"],
      showPicker: false,
      showArea: false,
      pickerList: [],
      pickerType: "",
      form: {
        id: "",
        orderId: "",
        name: "",
        mobile: "",
        gender: "",
        nation: "",
        politicalOutlook: "",
        papers: "",
        company: "",
        post: "",
        brithday: "",
        companyAddress: "",
        companyDetailAddress:'',
        dataAddress: "",
        dataDetailAddress:'',
        email: "",
        remark: "",
      },
      reflectArr: {
        A: "mobile",
        B: "name",
        C: "gender",
        D: "nation",
        E: "politicalOutlook",
        F: "brithday",
        G: "papers",
        H: "company",
        I: "post",
        J: "companyAddress",
        K: "dataAddress",
        L: "remark",
        M: "email",
      },
      areaList,
      companyAddressCode: [],
      dataAddressCode: [],
      currentDate: new Date(1990, 0, 1),
      minDate: new Date(1945, 0, 1),
      maxDate: new Date(),
      info: {},
      ifSave:'save',//save 或 noSave
      ifDelete:'yes',
    };
  },
  created() {
    let { info, applyField, meetId,meetStatus } = this.$route.params;
    if(!meetId){
      this.$router.go(-1)
    }
    this.applyField = applyField && applyField.split(",");
    this.meetId = meetId;
    this.meetStatus=meetStatus;
    console.log(this.meetStatus)
    this.ifSave=meetStatus<3?'save':'noSave';//是否有保存按钮

    this.form = Object.assign({}, this.form, info);
    this.form.gender = info.gender?(info.gender == 1 ? "女" : "男"):'';
    console.log(info)
    this.info = { ...info };
    this.companyAddressCode=info.companyAddressCode;
    this.dataAddressCode=info.dataAddressCode;
    this.ifDelete=this.$route.params.ifDelete?this.$route.params.ifDelete:'yes'
  },
  methods: {
    //提交
    onSubmit() {
      let ifPhone=this.judgeParam('A');
      let ifID=this.judgeParam('G');
      let ifEmail=this.judgeParam('M');
      if(ifPhone&&(!this.pattern.test(this.form.mobile))){
        Notify({ type: 'warning', message: '请输入正确格式的手机号码' });
        return false;
      }
      // if(ifID&&(!this.idPattern.test(this.form.papers))){
      //   Notify({ type: 'warning', message: '请输入正确格式的身份证号' });
      //   return false;
      // }
      if(ifEmail&&(!this.emailPattern.test(this.form.email))){
        Notify({ type: 'warning', message: '请输入正确格式的邮箱' });
        return false;
      }
      let obj = {};
      this.applyField.map((item) => {
        let key = this.reflectArr[item];
        obj[key] = this.form[key];
        if (key == "gender") {
          obj[key] = this.form[key] == "男" ? "0" : "1";
        }
        if(key=='companyAddress'){
          obj['companyDetailAddress']=this.form.companyDetailAddress
          obj['companyAddressCode']=this.companyAddressCode
        }
        if(key=='dataAddress'){
          obj['dataDetailAddress']=this.form.dataDetailAddress
          obj['dataAddressCode']=this.dataAddressCode
        }
      });

      let clientId = localStorage.getItem("clientId");
      let orderId = this.info.orderId ? this.info.orderId : "";
      let data = Object.assign({}, obj, {
        id: orderId,
        meetId: this.meetId,
        createClientId: clientId,
        ocId:this.info.id||''
      });

      generateMeetOrder(data).then((res) => {
        if (res.status == "S") {
          Toast("提交成功！");
          if(this.ifDelete=='no'){
            this.$router.push('./myMeetDetail?orderId='+orderId);
          }else{
            this.$router.replace({
              name: "meetSignUp",
              params: { id: this.meetId },
            });
          }
        }
      });
    },
    //删除参会人
    delAttender() {
      Dialog.confirm({
        title: "删除",
        message: "是否确认删除参会人？",
      })
        .then(() => {
          delAttender({ ids: this.info.id }).then((res) => {
            if (res.status == "S") {
              this.showModal = false;
              this.$router.replace({
                name: "meetSignUp",
                params: { id: this.meetId },
              });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    handleShowPicker(type) {
      if (type == "gender") {
        this.pickerList = [...this.genderList];
      } else if (type == "politicalOutlook") {
        this.pickerList = [...this.politicList];
      } else if (type == "born") {
        this.pickerList = [];
      }
      this.pickerType = type;
      this.showPicker = true;
    },
    onConfirmPicker(value) {
      if (this.pickerType == "gender") {
        this.form.gender = value;
      } else if (this.pickerType == "politicalOutlook") {
        this.form.politicalOutlook = value;
      } else if (this.pickerType == "born") {
        value = value.toLocaleDateString();

        this.form.brithday = moment(value).format("YYYY-MM-DD");
      }
      this.showPicker = false;
    },
    cancelPicker() {
      this.showPicker = false;
      this.pickerList = [];
    },
    handleShowArea(type) {
      this.pickerType = type;
      this.showArea = true;
    },
    onConfirmArea(value) {
      console.log(value)
      var name = value[0].name + value[1].name + value[2].name;
      if (this.pickerType == "companyAddress") {
        this.companyAddressCode = value[0].code+','+value[1].code+','+value[2].code;
        this.form.companyAddress = name;
      } else {
        this.dataAddressCode = value[0].code+','+value[1].code+','+value[2].code;;
        this.form.dataAddress = name;
      }
      this.showArea = false;
    },
    judgeParam(val) {
      let idx = this.applyField.indexOf(val);
      return idx > -1 ? true : false;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep.van-button {
  border-radius: 5px;
  height: 0.41rem;
}
::v-deep.van-popup--bottom,
::v-deep.van-overlay {
  width: 100vw;
}
::v-deep.van-cell {
  padding: 0.15rem 0.2rem;
  .van-field__label {
    color: #333;
  }
  .van-field__control,
  .van-field__right-icon {
    color: #666;
  }
}

.editPage {
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  padding-top: 0.1rem;
  box-sizing: border-box;
  .line {
    height: 0.1rem;
    background: #f5f5f5;
  }
  .box {
    height: calc(~"100vh - 1.47rem");
    overflow-y: auto;
  }
  .tallBox {
    height: calc(~"100vh - 0.91rem");
  }
  .tallerBox{
     height: calc(~"100vh - 0.1rem");
  }

  .btns {
    padding: 0.15rem 0.12rem;
    background: #fff;
  }
  .mar-t-15 {
    margin-top: 0.15rem;
  }
}
</style>
